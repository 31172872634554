/* global $ */

(function (scope) {
  "use strict";

  /**
   *
   */
  var BaseFormsView = (scope.BaseFormsView = function (controller) {
    this.controller = controller;

    // mostly for the print page
    // not the best place, but we can move it later
    $(".forms input[type=range]").on("input", this.updateRange);
    $(".forms input[type=range]").on("change", this.updateRange);
    $(".forms input[type=range]").on("update", this.updateRange); // Custom event when updating field by .val()
    $(".forms input[type=range]").each(this.updateRange);

    $("#PrintMultipleListView").on(
      "input",
      ".forms input[type=range]",
      this.updateRange
    );
    $("#PrintMultipleListView").on(
      "change",
      ".forms input[type=range]",
      this.updateRange
    );
    $("#PrintMultipleListView").on(
      "update",
      ".forms input[type=range]",
      this.updateRange
    );

    this.$input_password = $(".forms input:password");
    this.$showPassword = $(".forms .show_password");

    this.initBasicForm();
    this.initFormPassword();
  });

  /**
      Add or remove the error line on any input
    */
  BaseFormsView.prototype.initBasicForm = function () {
    $(".forms input[required=required]").change(function () {
      if ($(this).val() != "") {
        $(this).addClass("isValid");
        $(this).removeClass("isUnvalid");
      } else {
        $(this).removeClass("isValid");
        $(this).addClass("isUnvalid");
      }
    });

    $(".forms select ").change(function () {
      $(this).removeClass("isUnvalid");
    });

    $(".forms input[type=checkbox]").change(function () {
      $(this).removeClass("isUnvalid");
    });
  };

  /**
      Init the show/hide password on login/signup
    */
  BaseFormsView.prototype.initFormPassword = function () {
    //show/hide the password

    this.$showPassword
      .mousedown(
        function () {
          this.$input_password.prop("type", "text");
          this.defaultIconPassword();
        }.bind(this)
      )
      .mouseup(
        function () {
          this.$input_password.prop("type", "password");
          this.mouseOnIconPassword();
        }.bind(this)
      )
      .mouseout(
        function () {
          this.$input_password.prop("type", "password");
          this.mouseOnIconPassword();
        }.bind(this)
      );
  };

  BaseFormsView.prototype.defaultIconPassword = function () {
    this.$showPassword.removeClass("icon-eye-blocked");
    this.$showPassword.addClass("icon-eye");
  };
  BaseFormsView.prototype.mouseOnIconPassword = function () {
    this.$showPassword.addClass("icon-eye-blocked");
    this.$showPassword.removeClass("icon-eye");
  };

  /**
      Change the width of the slider on print page based on a log value
    */
  BaseFormsView.prototype.updateRange = function () {
    var $element =
      arguments.length > 1 ? $(arguments[1]) : $(arguments[0].currentTarget);
    var value = ($element.val() / $element.attr("max")) * 100;
    $element.siblings(".range-line").width(value + "%");
  };
})((window.sculpteo = window.sculpteo || {}));
