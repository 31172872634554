var sculpteoNotification = (function (window) {
  var sculpteoNotification = function (joblist) {
      return new sculpteoNotification.fn.init(joblist);
    },
    recvType = {},
    msgQueue = {},
    channels = {};

  sculpteoNotification.fn = sculpteoNotification.prototype = {
    init: function (selector) {
      if (!selector) {
        return this;
      }

      /* Currently we only support a list of message types */
      this.selector = selector.split(/ /);
      this.channel_id = window.channel_id;

      return this;
    },

    selector: [],

    /* Register to the "message received" event */
    recv: function (fn, data) {
      // Log("register notification on " + this.selector);
      var i = 0;

      while ((type = this.selector[i++])) {
        var dfd;
        if (this.channel_id.hasOwnProperty("promise")) {
          dfd = this.channel_id;
        } else {
          dfd = $.Deferred().resolve(this.channel_id);
        }
        (function (msg_type) {
          dfd.then(
            function () {
              // channel_id arrives with an ajax html and it's inserted into the DOM directly
              this.channel_id = window.channel_id;
              this.startListening();

              /* Register */
              recvType[this.channel_id][msg_type] =
                recvType[this.channel_id][msg_type] || [];
              recvType[this.channel_id][msg_type].push({ fn: fn, data: data });

              /* Replay history */
              var j = 0;

              while ((msg = msgQueue[this.channel_id][j++])) {
                if (msg.type == msg_type) {
                  //try {
                  if (data) {
                    fn(msg, data);
                  } else {
                    fn(msg);
                  }
                  //} catch (err) {
                  //if (typeof log != 'undefined') {
                  //log("notification: call to " + fn + "(msg=" + msg + ", data="+data + ") failed: " + err.description);
                  //}
                  //}
                }
              }
            }.bind(this)
          );
        }).bind(this)(type);
      }
      return this;
    },

    startListening: function () {
      if (!channels[this.channel_id]) {
        recvType[this.channel_id] = {};
        msgQueue[this.channel_id] = [];
        channels[this.channel_id] = new NchanSubscriber(
          URLS["notification"] + "?channel=" + this.channel_id,
          {
            subscriber: ["websocket", "longpoll"],
            reconnect: "persist",
            shared: true,
          }
        );
        channels[this.channel_id].on(
          "message",
          function (msg, meta) {
            msg = JSON.parse(msg);
            msgQueue[this.channel_id].push(msg);

            if (recvType[this.channel_id][msg.type]) {
              var i = 0;

              while ((info = recvType[this.channel_id][msg.type][i++])) {
                try {
                  if (info.data) {
                    info.fn(msg, info.data);
                  } else {
                    info.fn(msg);
                  }
                } catch (err) {
                  if (log) {
                    log(
                      "notification: call to " +
                        info.fn +
                        "(msg=" +
                        msg +
                        ", info.data=" +
                        info.data +
                        ") failed: " +
                        err.description
                    );
                  }
                }
              }
            }
          }.bind(this)
        );
        channels[this.channel_id].start();
      }
    },
  };
  sculpteoNotification.fn.init.prototype = sculpteoNotification.fn;

  // see http://blog.fastmail.fm/2012/11/26/inter-tab-communication-using-local-storage/
  // uses localStorage to dispatch messages accross open tabs
  var BroadCaster = function (options) {
    this.settings = jQuery.extend({}, BroadCaster.defaults, options);
    this.storage = !!(window.localStorage && window.StorageEvent);
    this._ping = null;

    // Browser sniffing (https://github.com/flowersinthesand/portal)
    var ua = navigator.userAgent.toLowerCase(),
      match =
        /(chrome)[ \/]([\w.]+)/.exec(ua) ||
        /(webkit)[ \/]([\w.]+)/.exec(ua) ||
        /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua) ||
        /(msie) ([\w.]+)/.exec(ua) ||
        (ua.indexOf("compatible") < 0 &&
          /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua)) ||
        [],
      browser = {};

    browser[match[1] || ""] = true;
    browser.version = match[2] || "0";

    // The storage event of Internet Explorer and Firefox 3 works strangely
    if (
      browser.msie ||
      (browser.mozilla && browser.version.split(".")[0] === "1")
    ) {
      this.storage = false;
    }

    this.isMaster = false;

    if (this.storage) {
      var now = new Date(),
        ping = 0;

      try {
        ping = +localStorage.getItem("ping") || 0;
      } catch (error) {}

      if (now - ping > 45000) {
        this.becomeMaster(true);
      } else {
        this.loseMaster(true);
      }

      window.addEventListener("storage", this, false);
      window.addEventListener("unload", this, false);
    } else {
      this.becomeMaster();
    }
  };

  BroadCaster.defaults = {
    id: "",
    masterDidChange: function (isNowMaster) {},

    onMessage: function () {},
  };

  BroadCaster.prototype = {
    destroy: function () {
      if (!this.storage) {
        return;
      }

      if (this._ping) {
        clearTimeout(this._ping);
      }

      if (this.isMaster) {
        try {
          localStorage.setItem("ping", 0);
        } catch (error) {}
      }

      window.removeEventListener("storage", this, false);
      window.removeEventListener("unload", this, false);
    },

    handleEvent: function (event) {
      if (event.type === "unload") {
        this.destroy();
      } else {
        var type = event.key,
          ping = 0,
          data;

        if (type === "ping") {
          if (!this.storage) {
            return;
          }

          try {
            ping = +localStorage.getItem("ping") || 0;
          } catch (error) {}

          if (ping) {
            this.loseMaster();
          } else {
            // We add a random delay to try avoid the race condition in
            // Chrome, which doesn't take out a mutex on local storage. It's
            // imperfect, but will eventually work out.
            clearTimeout(this._ping);
            this._ping = setTimeout(
              this.becomeMaster.bind(this),
              ~~(Math.random() * 1000)
            );
          }
        } else if (type === "broadcast") {
          if (!this.storage) {
            return;
          }

          try {
            data = JSON.parse(localStorage.getItem("broadcast"));
            this.settings.onMessage(data);
          } catch (error) {}
        }
      }
    },

    becomeMaster: function (startup) {
      if (!this.storage) {
        this.settings.masterDidChange(true);

        return;
      }

      // If (!this.isMaster) log("become master");
      if (startup) {
        // Clear queue of messages
        try {
          localStorage.setItem("messages", JSON.stringify([]));
        } catch (error) {}
      }

      try {
        localStorage.setItem("ping", Date.now());
      } catch (error) {}

      clearTimeout(this._ping);
      this._ping = setTimeout(
        this.becomeMaster.bind(this),
        20000 + ~~(Math.random() * 10000)
      );
      var wasMaster = this.isMaster;

      this.isMaster = true;

      if (!wasMaster) {
        this.settings.masterDidChange(this.isMaster);
      }
    },

    loseMaster: function (startup) {
      // If (this.isMaster) log("lose master");
      if (!this.storage) {
        return;
      }

      if (startup) {
        // Replay messages *locally* at startup (new listener)
        var messages = localStorage.getItem("messages");

        if (messages) {
          messages = JSON.parse(messages);

          // Log("replay messages");
          for (var i_m = 0; i_m < messages.length; i_m++) {
            // Log(messages[i_m]);
            this.settings.onMessage(JSON.parse(messages[i_m]));
          }
        }
      }

      clearTimeout(this._ping);
      this._ping = setTimeout(
        this.becomeMaster.bind(this),
        35000 + ~~(Math.random() * 20000)
      );
      var wasMaster = this.isMaster;

      this.isMaster = false;

      if (wasMaster) {
        this.settings.masterDidChange(this.isMaster);
      }
    },

    broadcast: function (msgStr) {
      try {
        this.settings.onMessage(JSON.parse(msgStr));
      } catch (error) {
        //json parsing failed because the message is not
        //correctly json formatted, it can be a 503 timeout
        //for example, ignoring the message
        if (error instanceof SyntaxError) {
          return;
        }
        throw error;
      }

      if (!this.storage) {
        return;
      }

      try {
        localStorage.setItem("broadcast", msgStr);
        var messages = localStorage.getItem("messages");

        if (typeof messages != "undefined") {
          messages = JSON.parse(messages);
          messages.push(msgStr);
        } else {
          messages = [];
        }

        localStorage.setItem("messages", JSON.stringify(messages));
      } catch (error) {}
    },
  };

  // https://gist.github.com/amcgregor/1821386
  var Channel = function (options) {
    this.settings = jQuery.extend({}, Channel.defaults, options);
    this.alive = true;
    this.failures = 0;
    var _this = this;

    this.broadcaster = new BroadCaster({
      id: this.settings.channel,
      onMessage: function (data) {
        _this.settings.onMessage(_this.settings.channel, data);
      },

      masterDidChange: function (isNowMaster) {
        // Log("master did change" + isNowMaster);
        if (isNowMaster) {
          _this.alive = true;
          _this.listen();
        } else {
          _this.alive = false;
        }
      },
    });
    jQuery(this).bind("channel.message", function (e, data) {
      _this.broadcaster.broadcast(data);
    });

    return this;
  };

  Channel.defaults = {
    publish: "/pub",
    subscribe: "/sub",
    channel: "general",
    accept: "text/plain, application/json",
    type: "text",
    retry: 500, // Retry after 0.5 second
    timeout: 300000, // 5 minutes
    last_modified: null,
    etag: null,
  };

  Channel.prototype = {
    listen: function () {
      var self = this;

      function closure() {
        var headers = {};
        if (this.settings.last_modified) {
          headers = {
            "If-Modified-Since": this.settings.last_modified,
            "If-None-Match": this.settings.etag,
          };
        }
        jQuery
          .ajax(this.settings.subscribe, {
            accept: this.settings.accept,
            cache: false,
            data: { channel: this.settings.channel },
            dataType: this.settings.type,
            global: false,
            headers: headers,
            ifModified: true,
            type: "GET",
            context: this,
            timeout: this.settings.timeout,
          })
          .done(this.success)
          .fail(this.failure)
          .complete(this.done);
      }

      if (this.failures) {
        setTimeout(function () {
          closure.apply(self);
        }, this.settings.retry);

        return;
      }

      setTimeout(function () {
        closure.apply(self);
      }, 0);
    },

    error: function (xhr, status) {
      if (status == "abort") {
        this.alive = false;
      } else if (status == "error" || status == "parsererror") {
        this.failures++;
      }

      if (this.failures > 3) {
        this.alive = false;
      }

      if (log) {
        log("Failure:", xhr, status);
      }
    },

    success: function (data, status, xhr) {
      // Reset failure count.
      this.failures = 0;
      $(this).trigger("channel.message", [data]);
    },

    done: function (xhr, status) {
      // Notmodified, error, timeout, abort, parsererror
      if (status != "success") {
        jQuery(this).trigger("channel." + status, [xhr]);

        if (status == "error") {
          this.alive = false;
        }

        log("Notification: " + status);
      } else {
        this.settings.last_modified = xhr.getResponseHeader("Last-Modified");
        this.settings.etag = xhr.getResponseHeader("etag");
      }

      if (this.alive) {
        this.listen();
      }
    },

    send: function (data) {
      var encoded = JSON.stringify(data, null, 2);
      var url =
        this.settings.publish +
        "?" +
        $.param({ channel: this.settings.channel });

      return jQuery.ajax(url, {
        accept: this.settings.accept,
        cache: true,
        data: encoded,
        global: false,
        type: "POST",
        context: this,
        mimeType: "application/json",
      });
    },
  };

  // Expose to the global object
  return (window.sculpteoNotification = sculpteoNotification);
})(window);
