/* global $ */
(function (sculpteo) {
  "use strict";

  var BaseController = (sculpteo.BaseController = function () {
    // Initialize views
    this.views = {};
    this.views.menu = new sculpteo.BaseMenuView(this);
    this.views.loginSignup = new sculpteo.BaseLoginSignupView(this);
    this.views.connectedMenu = new sculpteo.BaseConnectedMenuView(this);
    this.views.menuMobile = new sculpteo.BaseMenuMobileView(this);
    this.views.forms = new sculpteo.BaseFormsView(this);
  });
})((window.sculpteo = window.sculpteo || {}));

// launch the controller
$().ready(function () {
  window.baseController = new sculpteo.BaseController();
});
