(function (sculpteo) {
  "use strict";

  const Countdown = (sculpteo.Countdown = function (element, end_date) {
    this.element = element;
    this.countdown = this.element.getElementsByClassName("countdown")[0];
    this.countdown_text =
      this.element.getElementsByClassName("countdown-text")[0];
    this.days = this.element.getElementsByClassName("days")[0];
    this.hours = this.element.getElementsByClassName("hours")[0];
    this.minutes = this.element.getElementsByClassName("minutes")[0];
    this.seconds = this.element.getElementsByClassName("seconds")[0];
    this.countdown_over =
      this.element.getElementsByClassName("countdown-over")[0];

    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    const end_date_ms = end_date.getTime();

    const update = function () {
      const now_ms = new Date().getTime();
      const delta = end_date_ms - now_ms;

      this.days.innerText = Math.floor(delta / day);
      this.hours.innerText = Math.floor((delta % day) / hour);
      this.minutes.innerText = Math.floor((delta % hour) / minute);
      this.seconds.innerText = Math.floor((delta % minute) / second);

      if (delta < 0) {
        this.element.classList.add("disabled");
        clearInterval(this.interval);
      }
    }.bind(this);

    update();
    this.interval = setInterval(update, 1000);
  });
})((window.sculpteo = window.sculpteo || {}));
