/* global $ */

(function (scope) {
  "use strict";

  /**
   *
   */
  var BaseConnectedMenuView = (scope.BaseConnectedMenuView = function (
    controller
  ) {
    this.controller = controller;

    this.$el = $("#header");

    this.$navCart = $(".nav-shopping", this.$el);
    this.$navCartBadge = $(".nav-shopping-badge", this.$el);
    this.$navCartName = $(".nav-cart-name", this.$el);
    this.$navCartNameContainer = $(".nav-cart-name-container", this.$el);

    this.ajax = null;

    window.updateMenuAjaxFunction = this.ajaxCall.bind(this);

    // init the first ajax call
    this.ajaxCall();
  });

  /**
   * This gets called once on the first page view after signup
   */
  BaseConnectedMenuView.prototype._firstLogin = function (response) {
    // retrieve the signup method
    var signup_method = "unknown";
    if (response.hasOwnProperty("signup_method") && response.signup_method) {
      signup_method = response.signup_method;
    }

    // GA sign_up event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#sign_up
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "sign_up",
      method: signup_method,
    });
  };

  BaseConnectedMenuView.prototype.ajaxCall = function () {
    this.ajax = $.ajax({
      url: URLS.menuAjaxContent,
      type: "GET",
      data: {},
      success: function (response) {
        var cartCount = parseInt(response["cart_count"]);

        // changed the value of gtm-value for google analytics
        this.$navCart.attr("data-gtm-value", cartCount);

        // update the badge content + css if sup to 0
        if (!isNaN(cartCount)) {
          this.$navCartBadge.html(cartCount);
        }
        if (response["desc"]) {
          this.$navCartName.html(response["desc"]);
          this.$navCartNameContainer.removeClass("hidden");
        }

        if (cartCount > 0) {
          this.$navCartBadge.addClass("badge-active");
        }

        // we reuse this menu request that is done on every page to implement a first login mechanism
        if (response.hasOwnProperty("first_login") && response.first_login) {
          $.get(URLS.first_login_confirmation)
            .done(
              function () {
                // first_login confirmed (this garantees that _firstLogin() is called only once per user)
                this._firstLogin(response);
              }.bind(this)
            )
            .fail(function (e) {
              console.error("error", e);
            });
        }
      }.bind(this),
    });
  };
})((window.sculpteo = window.sculpteo || {}));
