/* global $ */

(function (scope) {
  "use strict";

  /**
   *
   */
  var BaseLoginSignupView = (scope.BaseLoginSignupView = function (controller) {
    this.controller = controller;

    this.$header = $("#header");

    this.$loginForm = $("#login_form");
    this.$signupForm = $("#signup_form");

    this.$container = $(".modal_login-signup");

    this.dfdLoginSignup = false;
    this.redirectLoginSignup = true;
    this.currentPopin = false;
    this.nextUrl = undefined;

    $("body").on(
      "click",
      ".menu_login_popin",
      this.displayLoginPopin.bind(this)
    );
    $("body").on(
      "click",
      ".menu_signup_popin",
      this.displaySignupPopin.bind(this)
    );
    $("body").on("click", ".popin_background", this.hidePopin.bind(this));
    $(".modal_login-signup .fb_button").on(
      "click",
      this.facebookLogin.bind(this)
    );

    //if we are in the page login and signup on desktop, we don't need the popin to show up on login/signup menu click
    if ($(".page_container").length) {
      $(".popin_wrapper").empty();
    } else {
      //code for the forms in popin
      //django need to know that the form will be in ajax and that is a form for login or signup
      var hiddenAjaxInput =
        '<input type="hidden" name="login_signup" value="1" />';
      $("#signup_form").prepend(hiddenAjaxInput);
      $("#login_form").prepend(hiddenAjaxInput);

      this.bindEventPopin();
    }
  });

  BaseLoginSignupView.prototype.bindEventPopin = function () {
    $(".popin_container-login #login_form").submit(
      function (evt) {
        this.preventDefaultAll(evt);
        if (!$(".popin_container-login .submit_button").hasClass("waiting")) {
          this.requestAjax(
            "login",
            ajax_url_login,
            $("#login_form").serialize()
          );
        }
      }.bind(this)
    );
    $(".popin_container-signup #signup_form").submit(
      function (evt) {
        this.preventDefaultAll(evt);
        if (!$(".popin_container-signup .submit_button").hasClass("waiting")) {
          this.requestAjax(
            "signup",
            ajax_url_signup,
            $("#signup_form").serialize()
          );
        }
      }.bind(this)
    );
  };

  BaseLoginSignupView.prototype.isMobile = function () {
    if ($(window).width() < 1024) {
      return true;
    }

    return false;
  };

  BaseLoginSignupView.prototype.preventDefaultAll = function (evt) {
    evt.preventDefault();
    evt.stopPropagation();
  };

  BaseLoginSignupView.prototype.requestAjax = function (form, url, data) {
    //remove all errors on both forms
    $(".errorlist", this.$loginForm).remove();
    $(".errorlist", this.$signupForm).remove();

    //because we are in ajax, we need to show that something is happening
    //the form "hangs" : connect button is greyed out
    $(".submit-container", this.$container).addClass("waiting");

    var finalurl = url;
    // if there is no next, get the current pathname and redirect to it
    if (typeof this.nextUrl === "undefined") {
      this.nextUrl = window.location.pathname;
    }
    finalurl += "?next=" + encodeURIComponent(this.nextUrl);

    $.ajax({
      type: "POST",
      url: finalurl,
      data: data,

      // form stops hanging after treating demand
      complete: function () {
        $(".submit-container", this.$container).removeClass("waiting");
      },
      success: function (json) {
        var data = JSON.parse(json);
        if (data.success == false) {
          if (form == "login") {
            this.loginErrors(data);
          } else if (form == "signup") {
            this.signupErrors(data);
          }
        } else if (data.success == true) {
          User.isLoggedIn = true;
          window.csrftoken = Utils.getCookie("csrftoken");

          if (this.dfdLoginSignup != false) {
            this.dfdLoginSignup.resolve();
            this.dfdLoginSignup = false;
          }

          if (this.redirectLoginSignup) {
            window.location = data.url;
          } else {
            this.hidePopin();
          }
        }
      }.bind(this),
      error: function (xhr, errmsg, err) {
        // 403 error happens when CSRF token is corrupted.
        if (xhr.status == 403) {
          $(".generic_error").append(
            "<ul class='errorlist'><li>" +
              gettext("Error") +
              ": " +
              gettext("Expired form, please refresh page") +
              "</li></ul>"
          );
        } else {
          console.log(xhr.status + ": " + xhr.responseText);
          $(".generic_error").append(
            "<ul class='errorlist'><li>" +
              gettext("Error") +
              ": " +
              xhr.status +
              "</li></ul>"
          );
        }
      },
    });
  };

  BaseLoginSignupView.prototype.loginErrors = function (data) {
    if (data.errors.__all__) {
      $("#login_form .generic_error").append(
        "<ul class='errorlist'><li>" + data.errors.__all__ + "</li></ul>"
      );
      $("#login_form input").addClass("isUnvalid");
    }
    if (data.errors.password) {
      $("#login_form #id_password")
        .parent()
        .find(".line")
        .after(
          "<ul class='errorlist'><li>" + data.errors.password + "</li></ul>"
        );
      $("#login_form #id_password").addClass("isUnvalid");
    }
    if (data.errors.username) {
      $("#login_form #id_username")
        .parent()
        .find(".line")
        .after(
          "<ul class='errorlist'><li>" + data.errors.username + "</li></ul>"
        );
      $("#login_form #id_username").addClass("isUnvalid");
    }
  };

  BaseLoginSignupView.prototype.addPasswordValidationErrors = function (
    errors
  ) {
    var $errors = $("<ul class='errorlist'></ul>");
    for (var i = 0; i < errors.length; i++) {
      $errors.append("<li>" + errors[i] + "</li>");
    }
    $("#signup_form #id_password_errors").append($errors);
    $("#signup_form #id_password_signup").addClass("isUnvalid");
  };

  BaseLoginSignupView.prototype.signupErrors = function (data) {
    if (data.errors.__all__) {
      $("#signup_form .generic_error").append(
        "<ul class='errorlist'><li>" + data.errors.__all__ + "</li></ul>"
      );
      $("#signup_form input").addClass("isUnvalid");
    }
    if (data.errors.password1) {
      this.addPasswordValidationErrors(data.errors.password1);
    }
    if (data.errors.password2 && !data.errors.password1) {
      this.addPasswordValidationErrors(data.errors.password2);
    }
    if (data.errors.email) {
      $("#signup_form #id_email")
        .parent()
        .find(".line")
        .after("<ul class='errorlist'><li>" + data.errors.email + "</li></ul>");
      $("#signup_form #id_email").addClass("isUnvalid");
    }
  };

  BaseLoginSignupView.prototype.setNextParam = function (url, next) {
    if (next.startsWith("http")) {
      next = next.replace(/^.*\/\/[^\/]+/, "");
    }
    var query = { next: next };
    return $.param.querystring(url, query);
  };

  /**
   * Show a popin
   * @param popin_type Type of popin
   * @param redirect Wether to redirect on success
   * @return {*} Returns Deferred that executes if no redirect
   */
  BaseLoginSignupView.prototype.showPopin = function (
    popin_type,
    redirect,
    next
  ) {
    if (!this.currentPopin) {
      if (this.dfdLoginSignup) {
        return this.dfdLoginSignup.promise();
      }
      this.redirectLoginSignup =
        typeof redirect !== "undefined" ? redirect : true;
      this.currentPopin = popin_type;
      this.nextUrl = next;

      // append ?next url param to all alternate login/signup links
      if (typeof this.nextUrl !== "undefined") {
        $(".social_button, a.menu_login_popin").each(
          function (index, elem) {
            $(elem).attr(
              "href",
              this.setNextParam($(elem).attr("href"), this.nextUrl)
            );
          }.bind(this)
        );
      }
      this.dfdLoginSignup = $.Deferred();
    }
    if (!this.isMobile() && $(".page_container").length === 0) {
      $(".popin_background").fadeIn();
      $(".popin_wrapper").fadeIn();
      if (popin_type == "login") {
        $(".popin_container-signup").hide();
        $(".popin_container-login").show();
      } else {
        $(".popin_container-signup").show();
        $(".popin_container-login").hide();
      }
    }
    if (this.dfdLoginSignup) {
      return this.dfdLoginSignup.promise();
    }
    return $.Deferred().resolve().promise();
  };

  /**
   * Show the login popin
   * @param redirect Wether to redirect on success
   * @return {*} Returns Deferred that executes if no redirect
   */
  BaseLoginSignupView.prototype.showLogin = function (redirect, next) {
    if (!this.isMobile() && $(".page_container").length === 0) {
      return this.showPopin("login", redirect, next);
    } else {
      window.location = this.setNextParam(
        $(".menu_login_popin").attr("href"),
        window.location.toString()
      );
      return $.Deferred().promise();
    }
  };

  /**
   * Show the signup popin
   * @param redirect Wether to redirect on success
   * @return {*} Returns Deferred that executes if no redirect
   */
  BaseLoginSignupView.prototype.showSignUp = function (redirect, next) {
    if (!this.isMobile() && $(".page_container").length === 0) {
      return this.showPopin("signup", redirect, next);
    } else {
      window.location = this.setNextParam(
        $(".menu_signup_popin").attr("href"),
        window.location.toString()
      );
      return $.Deferred().promise();
    }
  };

  BaseLoginSignupView.prototype.hidePopin = function (evt) {
    $(".popin_background").fadeOut();
    $(".popin_wrapper").fadeOut();
    $(".popin_container-login").fadeOut();
    $(".popin_container-signup").fadeOut();

    this.currentPopin = false;

    if (this.dfdLoginSignup != false) {
      this.dfdLoginSignup.resolve();
      this.dfdLoginSignup = false;
    }
  };

  BaseLoginSignupView.prototype.displayLoginPopin = function (evt) {
    if (!this.isMobile() && $(".page_container").length === 0) {
      this.preventDefaultAll(evt);
      this.showLogin();
    } else {
      var params = $.deparam.querystring();
      if (params["next"]) {
        $(this).attr(
          "href",
          this.setNextParam($(evt.currentTarget).attr("href"), params["next"])
        );
      } else {
        $(this).attr(
          "href",
          this.setNextParam(
            $(evt.currentTarget).attr("href"),
            window.location.toString()
          )
        );
      }
    }
  };

  BaseLoginSignupView.prototype.displaySignupPopin = function (evt) {
    if (!this.isMobile() && $(".page_container").length === 0) {
      this.preventDefaultAll(evt);
      this.showSignUp();
    } else {
      var params = $.deparam.querystring();
      if (params["next"]) {
        $(this).attr(
          "href",
          this.setNextParam($(evt.currentTarget).attr("href"), params["next"])
        );
      } else {
        $(this).attr(
          "href",
          this.setNextParam(
            $(evt.currentTarget).attr("href"),
            window.location.toString()
          )
        );
      }
    }
  };

  BaseLoginSignupView.prototype.facebookLogin = function (evt) {
    $("#fb_form").submit();
  };
})((window.sculpteo = window.sculpteo || {}));
