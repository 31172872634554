/* global $ */

(function (scope) {
  "use strict";

  /**
   *
   */
  var BaseMenuMobileView = (scope.BaseMenuMobileView = function (controller) {
    this.controller = controller;

    this.$body = $("body");

    this.$header = $("#header.header-mobile");
    this.$container = $("#header-mobile-container");
    this.$wrapper = $(".nav-m-wrapper", this.$container);
    this.$slidersContainer = $(".nav-m-content", this.$container);
    this.$overlay = $("#nav-mobile-overlay");
    this.$hamburger = $("#nav-hamburger-menu");

    // by default the main one
    this.$currentSlide = $(
      '[data-nav-m-slide-value="nav-m-slide-main"]',
      this.$container
    );
    this.$previousSlide = null;
    this.$nextSlide = null;

    this.isAnimatingSlide = false;

    this.$header.on(
      "click",
      "#nav-hamburger-menu",
      this.displaySliders.bind(this)
    );
    this.$container.on(
      "click",
      "[data-nav-m-link-value]",
      this.changeSlide.bind(this)
    );

    this.$overlay.on("click", this.hideAll.bind(this));
  });

  /**
   */
  BaseMenuMobileView.prototype.displaySliders = function (evt) {
    // translate the menu on the screen
    this.$wrapper.addClass("nav-m-wrapper-translateX");

    // lock the body to avoid scrolling in the page
    this.$body.addClass("lock-position");

    // show the black overlay
    this.displayOverlay();
  };

  BaseMenuMobileView.prototype.displayOverlay = function (evt) {
    this.$overlay.addClass("visible");
  };

  BaseMenuMobileView.prototype.hideAll = function (evt) {
    // hide the black overlay
    this.$overlay.removeClass("visible");
    // pull back the menu
    this.$wrapper.removeClass("nav-m-wrapper-translateX");

    // lremove the lock on the body
    this.$body.removeClass("lock-position");
  };

  BaseMenuMobileView.prototype.changeSlide = function (evt) {
    // don't change the slid until the animation finish
    if (this.isAnimatingSlide) {
      return;
    }

    // get the nav link
    var $currentTarget = $(evt.currentTarget);

    // get the value of the next slide
    var slideValue = $currentTarget.data("nav-m-link-value");
    // direction for the current slide
    var translateDirectionCurrentSlide = null;

    // we just want to display the previous slider
    if (slideValue == "back") {
      // in this case the next slide is actually the previous one
      this.$nextSlide = this.$previousSlide;
      // set the direction for the current slide
      translateDirectionCurrentSlide = "nav-m-slide-translateX-right";
    } else {
      // get the next slide
      this.$nextSlide = $("[data-nav-m-slide-value=" + slideValue + "]");
      // set the direction for the current slide
      translateDirectionCurrentSlide = "nav-m-slide-translateX-left";
    }

    // clean + remove class to make it appear
    this.$nextSlide
      .addClass("nav-m-slide-visible")
      .removeClass("nav-m-slide-translateX-right nav-m-slide-translateX-left");

    // translate the current slide on the left or right based of what was clicked
    this.$currentSlide
      .addClass(translateDirectionCurrentSlide)
      .removeClass("nav-m-slide-visible");

    // save the current one as previousSlide
    this.$previousSlide = this.$currentSlide;
    // save the next one as the current slide
    this.$currentSlide = this.$nextSlide;

    this.$nextSlide = null;

    // set isAnimatingSlide to true and
    // set a timer to avoid double clicking or fast click on the links
    // 250ms   |   it's also set in the css as transition
    this.isAnimatingSlide = true;
    setTimeout(
      function () {
        this.isAnimatingSlide = false;
      }.bind(this),
      250
    );
  };
})((window.sculpteo = window.sculpteo || {}));
