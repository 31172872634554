(function (sculpteo) {
  "use strict";

  const bannerDataStorageKey = "banner-data";
  const bannerCloseTimeout = 0; // seconds

  const Banner = (sculpteo.Banner = function (element, spacerElement, action) {
    this.element = element;
    this.spacerElement = spacerElement;
    this.closeElement =
      this.element.getElementsByClassName("new-banner-close")[0];
    this.bannerText = this.element.getElementsByClassName("new-banner-text")[0];

    this.defaultData = {
      dontShowAgain: false,
      closeDate: null,
    };

    const storedData = localStorage.getItem(bannerDataStorageKey);
    this.data = storedData ? JSON.parse(storedData) : this.defaultData;

    this.saveData = function () {
      localStorage.setItem(bannerDataStorageKey, JSON.stringify(this.data));
    };

    this.open = function () {
      if (this.data.dontShowAgain) {
        console.log("Banner.open: dontShowAgain, can't open");
        return;
      }

      var delta = (Date.now() - this.data.closeDate) / 1000;
      if (delta < bannerCloseTimeout) {
        console.log("Banner.open: too recently closed, can't open");
        return;
      }

      this.element.classList.remove("hidden");
      this.spacerElement.style.height = this.element.offsetHeight + "px";
    };

    this.close = function () {
      this.element.classList.add("hidden");
      this.spacerElement.style.height = 0;
      this.data.closeDate = Date.now();
      this.saveData();
    };

    this.closeForever = function () {
      this.close();
      this.data.dontShowAgain = true;
      this.saveData();
    };

    this.closeElement.addEventListener(
      "click",
      function () {
        this.close();
      }.bind(this)
    );

    this.bannerText.addEventListener(
      "click",
      function () {
        this.close();
        action();
      }.bind(this)
    );

    this.open();
  });
})((window.sculpteo = window.sculpteo || {}));
