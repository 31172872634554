var sculpteoJob = (function (window) {
  var sculpteoJob = function (joblist) {
    return new sculpteoJob.fn.init(joblist);
  };

  /* Instance methods */
  sculpteoJob.fn = sculpteoJob.prototype = {
    init: function (joblist) {
      if (!joblist) {
        this.jobset = {};
        this.jobcount = 0;

        return this;
      }

      jobset = {};

      for (k in joblist) {
        jobset[joblist[k].uuid] = joblist[k];
      }

      this.jobset = jobset;
      this.jobcount = joblist.length;

      return this;
    },

    /* Register to the "job count" event (also called if list is empty) */
    count: function (fn) {
      /* Call first with the initial count */
      fn(this.jobcount);

      if (this.jobcount) {
        sculpteoNotification("job_state").recv(sculpteoJob.ready, {
          obj: this,
          fn: fn,
        });
      }

      return this;
    },

    /* Shortcut for the "all jobs are done" event (also called if list is empty) */
    ready: function (fn) {
      return this.count(function (count) {
        if (count == 0) {
          fn();
        }
      });
    },

    /* Register to a job event */
    bind: function (type, fn) {
      if (this.jobcount) {
        /* Listen to event of that class */
        var notification_class = "job_" + type;

        sculpteoNotification(notification_class).recv(sculpteoJob.bind, {
          obj: this,
          type: type,
          fn: fn,
        });
      }

      return this;
    },

    /* Shortcut for job success */
    success: function (fn) {
      return this.bind("state", function (job, msg) {
        if (msg.state == 4) {
          /* Success */
          fn(job, msg);
        }
      });
    },

    /* Shortcut for job failure */
    failure: function (fn) {
      return this.bind("state", function (job, msg) {
        if (msg.state == 5) {
          /* Failure */
          fn(job, msg);
        }
      });
    },

    /* Shortcut for job success or failure */
    done: function (fn) {
      return this.bind("state", function (job, msg) {
        if (msg.state == 4 || msg.state == 5) {
          /* Success or failure */
          fn(job, msg);
        }
      });
    },

    /* Shortcut for job progress */
    progress: function (fn) {
      return this.bind("progress", function (job, msg) {
        fn(job, msg);
      });
    },
  };
  sculpteoJob.fn.init.prototype = sculpteoJob.fn;

  /* Class methods */
  $.extend(sculpteoJob, {
    bind: function (msg, info) {
      var obj = info.obj;
      var type = info.type;
      var fn = info.fn;
      var uuid = msg.uuid;

      if (obj.jobset[uuid]) {
        var job = obj.jobset[uuid];

        if (job.age < msg.age) {
          fn(obj.jobset[uuid], msg);
        }
      }
    },

    ready: function (msg, info) {
      var obj = info.obj;
      var fn = info.fn;

      // Log("job state event "+ msg.uuid + " " + msg.state);
      if (msg.state == 4 || msg.state == 5) {
        /* Success or failure */
        var uuid = msg.uuid;

        // Log("done " + uuid + " for obj " + $.toJSON(obj));

        /* Remove job from set and publish new count */
        if (obj.jobset[uuid]) {
          var job = obj.jobset[uuid];

          if (job.age < msg.age) {
            delete obj.jobset[uuid];
            obj.jobcount--;
            fn(obj.jobcount);
          }
        }
      }
    },
  });

  // Expose to the global object
  return (window.sculpteoJob = sculpteoJob);
})(window);

(function (scope) {
  var waitStates = { 1: true, 2: true, 3: true, 6: true, 7: true };
  scope.waitStates = waitStates;

  scope.retryOnJob = function (promise, method, options) {
    var dfd = $.Deferred();
    promise.fail(dfd.reject);
    promise.done(function (data, statusText, jqXHR) {
      var waitJobHeader = jqXHR.getResponseHeader("X-Sculpteo-Waitjob");
      if (jqXHR.status == 202 && waitJobHeader) {
        if (waitStates[data.state]) {
          var job = sculpteoJob([data]);
          job.done(function (job, msg) {
            var prm = method.apply($, options);
            scope
              .retryOnJob(prm, method, options)
              .then(dfd.resolve, dfd.reject);
          });
        } else {
          method.apply($, options).then(dfd.resolve, dfd.reject);
        }
      } else {
        dfd.resolve(data, statusText, jqXHR);
      }
    });
    promise = dfd.promise();
    promise.error = promise.fail;
    return promise;
  };

  scope.retryGetOnJob = function () {
    return scope.retryOnJob($.get.apply($, arguments), $.get, arguments);
  };

  scope.retryPostOnJob = function () {
    return scope.retryOnJob($.post.apply($, arguments), $.post, arguments);
  };

  scope.retryAjaxOnJob = function () {
    return scope.retryOnJob($.ajax.apply($, arguments), $.ajax, arguments);
  };

  scope.retryGetJSONOnJob = function () {
    return scope.retryOnJob(
      $.getJSON.apply($, arguments),
      $.getJSON,
      arguments
    );
  };
})((window.waitForJob = window.waitForJob || {}));
