/* global $ */

(function (scope) {
  "use strict";

  /**
   *
   */
  var BaseMenuView = (scope.BaseMenuView = function (controller) {
    this.controller = controller;

    this.$el = $("#header.header-desktop");
    this.$outsideClickOverlay = $("#header-outside-click-overlay");

    this.overSubmenu = null;
    this.overTrigger = null;
    this.openedMenu = null;

    this.enterTimeout = null;
    this.exitTimeout = null;
    this.enterDelay = 160;
    this.exitDelay = 300;

    this.$el.on(
      "mouseenter",
      ".nav-submenu.visible",
      this._enterSubmenu.bind(this)
    );
    this.$el.on(
      "mouseleave",
      ".nav-submenu.visible",
      this._exitSubmenu.bind(this)
    );

    this.$el.on(
      "mouseenter",
      ".nav-submenu-trigger",
      this._enterTrigger.bind(this)
    );
    this.$el.on(
      "mouseleave",
      ".nav-submenu-trigger",
      this._exitTrigger.bind(this)
    );

    this.$el.on(
      "mouseleave",
      ".basf-materials-legend",
      this.hideBASFMaterials.bind(this)
    );
    this.$el.on(
      "mouseenter",
      ".basf-materials-legend",
      this.showBASFMaterials.bind(this)
    );

    // use an overlay to detect outside click
    // touch screens won't send an event when hovering out an element
    // and clicks on the very background of the page won't generate an event
    $("#header-outside-click-overlay").click(
      function (evt) {
        this.closeSubmenu();
      }.bind(this)
    );

    // immediate (no delay) open when explicitly clicking
    $(".nav-submenu-trigger").click(
      function (evt) {
        evt.stopPropagation();
        // prevent propagation so that only the clicks that are outside a nav-submenu-trigger
        // reach the #navbar close-on-click listener below
        var type = $(evt.currentTarget).find("span").data("nav-link");
        this.openSubmenu(type);
      }.bind(this)
    );

    // close on click on navbar unless clicked inside a nav-submenu-trigger c.f stopPropagation above
    $("#navbar").click(
      function (evt) {
        this.closeSubmenu();
      }.bind(this)
    );
  });

  BaseMenuView.prototype._exitTransit = function () {
    this._cancelExitTransit(); // cancel any existing transit
    this.exitTimeout = setTimeout(
      function () {
        this.closeSubmenu(); // NOTE: comment out this line to disable auto-closing and debug/work on menus
      }.bind(this),
      this.exitDelay
    );
  };

  BaseMenuView.prototype._cancelExitTransit = function () {
    if (this.exitTimeout) {
      clearTimeout(this.exitTimeout);
      this.exitTimeout = null;
    }
  };

  BaseMenuView.prototype._enterTransit = function (type) {
    this._cancelEnterTransit(); // cancel any existing transit
    this.enterTimeout = setTimeout(
      function () {
        this.openSubmenu(type);
      }.bind(this),
      this.enterDelay
    );
  };

  BaseMenuView.prototype._cancelEnterTransit = function () {
    if (this.enterTimeout) {
      clearTimeout(this.enterTimeout);
      this.enterTimeout = null;
    }
  };

  BaseMenuView.prototype._enterTrigger = function (evt) {
    this.overTrigger = evt.currentTarget;
    this._cancelExitTransit();
    var type = $(this.overTrigger).find("span").data("nav-link");
    this._enterTransit(type);
  };

  BaseMenuView.prototype._exitTrigger = function () {
    this.overTrigger = null;
    this._cancelEnterTransit();
    this._exitTransit();
  };

  BaseMenuView.prototype._enterSubmenu = function (evt) {
    this.overSubmenu = evt.currentTarget;
    this._cancelExitTransit();
  };

  BaseMenuView.prototype._exitSubmenu = function () {
    this.overSubmenu = null;
    this._exitTransit();
  };

  // TODO: type should just be the submenu id
  BaseMenuView.prototype._toggleSubmenu = function (type, open) {
    if (!open) this.hideBASFMaterials();

    // toggle submenu visibility, don't animate if a submenu is already opened to avoid flickering
    // due to alpha animation
    $('[data-nav-submenu="' + type + '"]', this.$el)
      .toggleClass("anim", this.openedMenu == null)
      .toggleClass("visible", open);
    $('.nav-link[data-nav-link="' + type + '"]', this.$el).toggleClass(
      "active",
      open
    );
  };

  BaseMenuView.prototype.openSubmenu = function (type) {
    if (type == this.openedMenu) return;

    this.$outsideClickOverlay.show();

    this._toggleSubmenu(type, true);

    // hide previously opened submenu if any
    if (this.openedMenu) this._toggleSubmenu(this.openedMenu, false);

    this.openedMenu = type;
  };

  BaseMenuView.prototype.closeSubmenu = function () {
    if (this.openedMenu) this._toggleSubmenu(this.openedMenu, false);

    this.$outsideClickOverlay.hide();

    this.openedMenu = null;
  };

  BaseMenuView.prototype.showBASFMaterials = function () {
    $(".materials-menu .item:not(.basf-material)").addClass("dim");
    $(".materials-menu .row-head").addClass("dim");
    $(".materials-menu .arrow-item").addClass("dim");
    $(".materials-menu .cat-item").addClass("dim");
    $(".materials-menu .item.basf-material").addClass("highlight");
  };

  BaseMenuView.prototype.hideBASFMaterials = function () {
    $(".materials-menu .dim").removeClass("dim");
    $(".materials-menu .highlight").removeClass("highlight");
  };
})((window.sculpteo = window.sculpteo || {}));
