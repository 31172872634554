(function (sculpteo) {
  "use strict";

  const outgrow_ids = {
    en: "5f328289023eb55612ee4603",
    fr: "5f6b0bed66cae2595ee2554e",
    es: "5f6df7b78c9fdd2644735c62",
    de: "5f6deefb8c9fdd26447334e7",
  };

  const Outgrow = (sculpteo.Outgrow = function () {
    this.open = function () {
      // get the language from the page's html attribute
      const lang = $("html").attr("lang");

      if (!outgrow_ids.hasOwnProperty(lang)) {
        console.warn("No survey for language", lang);
        return;
      }

      var outgrow_html =
        "<div id='" +
        outgrow_ids[lang] +
        "' " +
        "data-title='New Sign up' data-embedCookieDays='10' " +
        "data-embedScheduling='false' data-embedTimed='true' data-embedExit='false' " +
        "data-embedTimeFormat='0' data-embedTimeValue='5' data-embedBorderRadius='0' " +
        "data-embedFontSize='12' data-textcolor='#fb5f66' data-bgcolor='#000000' " +
        "data-prop='outgrow-p' data-type='outgrow-l' " +
        "data-url='https://sculpteo.outgrow.us/" +
        outgrow_ids[lang] +
        "?q=1' " +
        "data-text='Get Started'></div>";

      $("body").append($("<div/>").hide().html(outgrow_html));

      $.getScript(
        "//dyv6f9ner1ir9.cloudfront.net/assets/js/nploader.js",
        function () {
          initIframe(outgrow_ids[lang]);
          togglePopup(outgrow_ids[lang]);
        }
      );
    };

    this.close = function () {
      // todo
    };
  });
})((window.sculpteo = window.sculpteo || {}));
